import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';
import store from '@/store';
import router from '@/router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'loggeduser layout',
            component: () => import("./views/loggeduser_layout.vue"),
            beforeEnter: (to, from) => {
                if(!store.state.loggedUser.identifier)
                    return router.push("/redirect?link=" + to.path);
            },
            children: [
                {
                    path: '/rules',
                    name: 'rules',
                    component: () => import("./views/rules.vue"),
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import("./views/profile.vue"),
                },
                {
                    path: '/ranking',
                    name: 'ranking',
                    component: () => import("./views/ranking.vue"),
                },

            ]
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import("./views/dashboard.vue"),
        },
        {
            path: '/active-purchase',
            name: 'active purchase',
            meta: {
                showBackButton: true
            },
            component: () => import("./views/activePurchase.vue"),
        },
        {
            path: '/quiz',
            name: 'quiz',
            meta: {
                hideHeader: true
            },
            component: () => import("./views/challengeGame.vue"),
        },
    ];
};