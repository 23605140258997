import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'landing',
            component: () => import("./views/landing.vue"),
        },
        {
            path: '/welcome',
            name: 'welcome',
            meta: {
                showBackButton: true
            },
            component: () => import("./views/welcome.vue"),
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                showBackButton: true
            },
            component: () => import("./views/login.vue"),
        },
        {
            path: '/register',
            name: 'register',
            meta: {
                showBackButton: true
            },
            component: () => import("./views/register.vue"),
        },
        {
            path: '/login/hostess',
            name: 'login hostess',
            meta: {
                showBackButton: true
            },
            component: () => import("./views/loginHostess.vue"),
        },
        {
            path: '/unique-code-login/:username/:uniqueCode',
            name: 'unique-code-login',
            component: () => import('./views/uniqueCodeLogin.vue')
        },
        {
            path: '/completewinner/:emailAddress/:userExtractionResumeIdentifier',
            name: 'complete winner',
            component: () => import('./views/completeWinner.vue')
        },
    ];
};