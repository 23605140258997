import onboarding from '@/views/onboarding';
import loggeduser from '@/views/loggeduser';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    ...onboarding.routes(),
    ...loggeduser.routes()
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        var el = document.getElementById("loggeduser_div");
        if(el)
            el.scrollTo({ top: 0, behavior: <any>'instant' })
    }
})

export default router