import { createApp } from 'vue';
import { LocalizationServices } from './services/LocalizationServices';
import { UrlServices } from './services/UrlServices';
import App from './App.vue';
import router from './router';
import store from './store';
import modalPlugin from './plugins/modalPlugin';
import FTS from '@/filters';
import * as DIR from '@/directives';
import 'swiper/swiper.min.css';
import "swiper/modules/autoplay/autoplay.min.css";

import VueQrcode from '@chenfengyuan/vue-qrcode';

import './utils';
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';
import "@/assets/css/font.css";
import "@/assets/css/animation.css";
import "@/assets/css/transition.css";
import 'bootstrap/js/src/collapse.js';
import { debugAnalytics } from './config';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective)
    .directive('longpress', DIR.longpress)
    .component(VueQrcode.name, VueQrcode);

app.config.globalProperties.$filters = FTS;
app.config.globalProperties.$localizationService = LocalizationServices;
app.config.globalProperties.$urlServices = UrlServices;

if(debugAnalytics)
    console.error("Piattaforma non settata! Se è un debug non è un problema, se no uno forse c'è")

router.beforeEach((to, from, next) => {        
    next();
})

//questo aggiunge automaticamente ogni componente nella cartella 'components'
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

app.mount('#app');