import { vModelText as _vModelText, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-428d572e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["tabindex", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      type: "password",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      tabindex: _ctx.tabIndex,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitModel(_ctx.localValue))),
      readonly: _ctx.readonly
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.localValue],
      [_directive_localizedPlaceholder, { key: _ctx.placeholderKey, value: _ctx.placeholderValue,  prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
    ]),
    _createElementVNode("div", {
      class: "password_div pointer",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeShowPassword()))
    }, [
      (!_ctx.showPassword)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "app_mostra",
            text: "Mostra",
            class: "fs_12 fw_500"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "app_nascondi",
            text: "Nascondi",
            class: "fs_12 fw_500"
          }))
    ])
  ]))
}