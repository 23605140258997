import { StorageServices } from "./services/StorageServices";
import { wordpressUrl } from '@/config';
import router from "./router";
import route from "./router";
import store from "./store";
import * as OM from "./Model";

let viewWithServices = null;

export function assignViewWithServices(viewWithServicesComponent){
    viewWithServices = viewWithServicesComponent;
}

export function getViewWithServices(){
    return viewWithServices;
}

export function routerReplaceAndPush(replaceUrl: string, pushUrl: string){
    router.replace(replaceUrl)
    .then( () => {
        router.push(pushUrl);
    }).catch( () => {
        console.error("router replace error!");
    })
}

export function removeSpaces(text: string) : string {
    if(!text)
        return text;

    var temp = text.trim();

    while(temp.indexOf("  ") != -1)
        temp = temp.replace("  ", " ").trim();

    return temp;
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized)
        localized = new OM.LocalizedValue();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
} 

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function removeBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "hidden";
}
export function addBodyVerticalScroll() {
    document.getElementById("body").style.overflowY = "auto";
}

export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function getFileFromUri(uri: string): Promise<File>{
    if(uri.indexOf('://') === -1) 
        uri = "file://" + uri;

    let prom = new Promise<File>((resolve, reject) => {
        (<any>window).resolveLocalFileSystemURL(uri, (fileEntry) => {
            fileEntry.file((resFile) => {
                resolve(resFile);
            });
        }, err => {
            console.log(err)
        })
    });

    return prom;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if(!file){
            resolve("");
            return;
        }

        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }

        if(!window.cordova){
            var url = URL.createObjectURL(file);
            resolve(url);
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function createLinkAutologin(urlValue: string) : string {
    var loggedUser = StorageServices.getLoggedUser();
    var ris = wordpressUrl + "wp-json/cacciatesoroapi/v1/redirect?url=" + urlValue + "&data=" + loggedUser.secretWp; 
    return ris;
}

export function getFileFromBase64(string64: string, fileName:string) {
    const trimmedString = string64.replace('data:image/png;base64,', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);
  
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    const type = 'image/png';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}