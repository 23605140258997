import { CommonServices } from './CommonServices';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import store from '@/store';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    getLoggedUser() {
        var loggedUser = this.readFromLocalStorage<OM.LoggedUserVM>('loggedUser');

        if(!loggedUser){
            loggedUser = new OM.LoggedUserVM();
            loggedUser.preferredCulture = "it-IT";
            StorageServices.setLoggedUser(loggedUser);
        }

        return loggedUser;
    }
    
    setNewTokensByAuthResponse(tokens: OM.RefreshTokenVM){
        var token = tokens.token;
        var refreshToken = tokens.refreshToken;

        this.setNewTokens(token, refreshToken);
    }

    setNewTokens(token: string, refreshToken: string){
        StorageServices.setLoginToken(token);
        StorageServices.setLoginRefreshToken(refreshToken);

        var loggedUser = store.state.loggedUser;
        loggedUser.token = token;
        loggedUser.refreshToken = refreshToken;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    setLoggedUser(response: OM.LoggedUserVM) {
        window.localStorage.setItem('loggedUser', JSON.stringify(response));
    }

    setLoginToken(token: string) {
        return CommonServices.setAuthToken(token);
    }
    getLoginToken(): string {
        return window.localStorage.getItem('authtoken');
    }

    setQuestionsCount(questionsCount: number) {
        return window.localStorage.setItem('questionsCount', JSON.stringify(questionsCount));
    }
    getQuestionsCount(): number {
        var questionsCount = this.readFromLocalStorage<number>('questionsCount');
        return questionsCount;
    }

    setLoginRefreshToken(token: string) {
        return window.localStorage.setItem('refreshtoken', token);
    }
    getLoginRefreshToken(): string {
        return window.localStorage.getItem('refreshtoken');
    }

    setProfileSearchPreferencesCompleted(isCompleted: boolean) {
        window.localStorage.setItem('profileSearchPreferencesCompleted', JSON.stringify(isCompleted));
    }
    getProfileSearchPreferencesCompleted(): boolean {
        return <boolean>(window.localStorage.getItem('profileSearchPreferencesCompleted') === 'true');
    }
    setProfileSettingsCompleted(isCompleted: boolean) {
        window.localStorage.setItem('profileSettingsCompleted', JSON.stringify(isCompleted));
    }
    getProfileSettingsCompleted(): boolean {
        return <boolean>(window.localStorage.getItem('profileSettingsCompleted') === 'true');
    }

    setHideWelcomeToExperienceBanner(hide: boolean) {
        window.localStorage.setItem('hideWelcomeToExperienceBanner', JSON.stringify(hide));
    }
    getHideWelcomeToExperienceBanner(): boolean {
        return <boolean>(window.localStorage.getItem('hideWelcomeToExperienceBanner') === 'true');
    }

    setDontShowNextProfileMessage(dontShowNextProfileMessage: boolean) {
        window.localStorage.setItem('dontShowNextProfileMessage', JSON.stringify(dontShowNextProfileMessage));
    }
    getDontShowNextProfileMessage(): boolean {
        return <boolean>(window.localStorage.getItem('dontShowNextProfileMessage') === 'true');
    }

    setCurrentPosition(position: OM.Address) {
        window.localStorage.setItem('currentposition', JSON.stringify(position));
    }

    getCurrentPosition() : OM.Address | null {
        let data = this.readFromLocalStorage<OM.Address>('currentposition');

        return data;
    }

    setRegisteredPhoneNumber(phonenumber: VM.PhoneNumber) {
        window.localStorage.setItem('phonenumber', JSON.stringify(phonenumber));
    }

    getRegisteredPhoneNumber() {
        let data = this.readFromLocalStorage<VM.PhoneNumber>('phonenumber');

        return data;
    }
}

export let StorageServices = new _StorageServices();