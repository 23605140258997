import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  href: "/doc/regolamento_ufficiale_webapp.pdf",
  target: "_blank",
  class: "btn btn-primary mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_localized_text, {
      type: "p",
      class: "text",
      localizedKey: "app_Il concorso “Vinci i biglietti IDays 2024” è valido dall’8 giugno al 4 luglio 2024. Puoi accumulare punti giocando al quiz online oppure acquistando nei negozi di Merlata Bloom Milano.",
      text: "Il concorso “Vinci i biglietti IDays 2024” è valido dall’8 giugno al 4 luglio 2024. Puoi accumulare punti giocando al quiz online oppure acquistando nei negozi di Merlata Bloom Milano."
    }),
    _createVNode(_component_localized_text, {
      type: "p",
      class: "text",
      localizedKey: "app_Fino al 21 giugno puoi vincere 2 biglietti per i concerti del 27, 30 giugno o 6 luglio. Mentre dal 22 giugno al 4 luglio puoi vincere 2 biglietti per i concerti del 7, 9 o 12 luglio.",
      text: "Fino al 21 giugno puoi vincere 2 biglietti per i concerti del 27, 30 giugno o 6 luglio. Mentre dal 22 giugno al 4 luglio puoi vincere 2 biglietti per i concerti del 7, 9 o 12 luglio."
    }),
    _createVNode(_component_localized_text, {
      type: "h4",
      localizedKey: "app_Quiz",
      text: "Quiz",
      class: "ff_unbounded my-4"
    }),
    _createVNode(_component_localized_text, {
      type: "p",
      class: "text",
      localizedKey: "app_Le domande vertono sulla conoscenza di Merlata Bloom Milano e degli artisti che si esibiranno agli IDays 2024. Per ogni risposta corretta ottieni 5 punti e il tempo sarà determinante solo in condizione di parità di punteggio con altri concorrenti.",
      text: "Le domande vertono sulla conoscenza di Merlata Bloom Milano e degli artisti che si esibiranno agli IDays 2024. Per ogni risposta corretta ottieni 5 punti e il tempo sarà determinante solo in condizione di parità di punteggio con altri concorrenti."
    }),
    _createVNode(_component_localized_text, {
      type: "h4",
      localizedKey: "app_Acquisti in galleria",
      text: "Acquisti in galleria",
      class: "ff_unbounded my-4"
    }),
    _createVNode(_component_localized_text, {
      type: "p",
      class: "text",
      localizedKey: "app_Fai acquisti di qualsiasi importo nei negozi di Merlata Bloom Milano, porta gli scontrini all’Infopoint dalle 9:00 alle 22:00 e tramite questa webapp scansiona il QR Code che ti mostrerà la hostess. Con ogni scontrino scansionato ottieni ben 15 punti.",
      text: "Fai acquisti di qualsiasi importo nei negozi di Merlata Bloom Milano, porta gli scontrini all’Infopoint dalle 9:00 alle 22:00 e tramite questa webapp scansiona il QR Code che ti mostrerà la hostess. Con ogni scontrino scansionato ottieni ben 15 punti."
    }),
    _createElementVNode("a", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_Regolamento completo",
        text: "Regolamento completo"
      })
    ])
  ], 64))
}