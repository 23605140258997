import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/back.svg'
import _imports_1 from '@/assets/img/logo.svg'


const _withScopeId = n => (_pushScopeId("data-v-b44cb6cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center position-relative mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner_container = _resolveComponent("spinner-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["MBMIdaysContainer", {'layoutPadding' : _ctx.checkifIsInternalPage}])
  }, [
    _createVNode(_component_spinner_container),
    (!_ctx.$route.meta.hideHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.$route.meta.showBackButton)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _imports_0,
                class: "backButton",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
              }))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            src: _imports_1,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: "opacity",
              class: "duration04 no_leave_animation",
              appear: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_modals_container)
  ], 2))
}